 .Grey{
    background-color: rgba(0, 0, 0, 0.5);
}

 .Cyan{
    background-color: rgba(37, 158, 228, 0.6);
}

 .Black{
    background-color: rgba(0, 0, 0, 0.8);
}

 .LightGrey{
    background-color: rgba(59, 59, 59, 0.4);
}

.Img{
    min-width: 300px;
    max-width: 80%;
    height: auto;
}

.Logos{
    height: auto;
    width: 100%;
}