@import url('https://fonts.cdnfonts.com/css/bahnschrift');
                
html, body {
    margin: 0;
    padding: 0;
    height: 100%;
    box-sizing: border-box;                                                                  
    font-family: 'Bahnschrift', sans-serif;
}

.app-layout{
    min-height: 100vh;
    background-image: url("./images/bg-new.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-attachment: fixed;
}

.navbar-dark .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 1%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-toggler {
    padding: 0.25rem 0.75rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: rgb(102, 169, 214);
    border: 2px solid rgb(255, 255, 255);
    border-radius: 0.25rem;
    transition: box-shadow .15s ease-in-out;
}

.offcanvas{
    background-color: rgba(0, 0,0, 0.65);
}

.btn-close {
    box-sizing: content-box;
    width: 1em;
    height: 1em;
    padding: 0.25em 0.25em;
    border: 0;
    border-radius: 0.25rem;
    opacity: 1;
    filter: none;
    background: rgb(102, 169, 214) url("https://upload.wikimedia.org/wikipedia/en/thumb/6/61/Cross_icon_%28white%29.svg/1024px-Cross_icon_%28white%29.svg.png") center/1em auto no-repeat;
}