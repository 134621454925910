.links{
    width: 70% ;
}

.links a{
    color:white;
    text-decoration: none ;
    margin: 0 1rem ;
    padding: 8px 0 ;
}


.links a:hover {
    color: #66a9d6 ;
}

@media only screen and (max-width: 768px) {
    .links a{
        margin: 0 ;
    }
  }

