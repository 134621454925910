.Note{
    min-height: 350px;
    display: flex;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.8);
    justify-content: space-evenly;
}

.Back{
    background-color: rgba(59, 59, 59, 0.4);
    min-height: 84vh;
}