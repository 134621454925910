.AvatarImg{
    height: auto;
    width: 100%;
    max-width: 450px;
    max-height: 450px;
}

.Info{
    height: 88%;
    width: 90%;
    background-color: rgba(37, 158, 228, 0.7);
    /* max-width: 450px;
    max-height: 450px; */
}