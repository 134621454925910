.Icon{
    min-width: 100px;
    width:auto;
    max-width: 80%;
    height: auto;
}

.GreyImage{
    background-color: #a4a4a4;

}

.LightGreyImage{
    background-color:   #d0d2d5
}

.DarkGreyImage{
    background-color:#222323 ;
}
.Text{
    height: 88%;
    width: 64%;
    max-width: 190px;
    background-color: rgba(37, 158, 228, 0.85);
}