.TopDiv{
  background: url("../images/careers-bg.jpg") no-repeat center center fixed;
  background-size: cover;
  min-height: 550px;
}

.JobList{
    /* background-image: url("../images/bg.png"); */
    background-color: rgba(0, 0, 0, 0.6);
    /* background-size: cover;
    background-position: center center;
    background-repeat: no-repeat; */
}

.Message{
  background-color: rgba(0, 0, 0, 0.6);
  min-height: 380px;
}

::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 10px;
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: white; 
    border-radius: 10px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(231, 231, 231); 
  }