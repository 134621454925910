
.Main > .Grey{
    background-color: rgba(0, 0, 0, 0.5);
}

.Main > .Cyan{
    background-color: rgba(37, 158, 228, 0.6);
}

.Main > .Black{
    background-color: rgba(0, 0, 0, 0.8);
}

.Main > .LightGrey{
    background-color: rgba(59, 59, 59, 0.4);
}

.Img{
    min-width: 100px;
    width:auto;
    max-width: 80%;
    height: auto;
}

.GreyImage{
    background-color: #a4a4a4;

}

.LightGreyImage{
    background-color:   #d0d2d5
}

.DarkGreyImage{
    background-color:#222323 ;
}

.ImageDiv{
    position: relative;
    height: 500px;
}

.UpperDiv{
    height: 50%;
    background-color: rgba(37, 158, 228, 0.6);
}

.LowerDiv{
    height: 50%;
    background-color: rgba(59, 59, 59, 0.4);
}

.UpperImg{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 300px;
    max-width: 100%;
    height: auto;
    max-height:100% ;
}


